import { css } from '@emotion/react'
import * as React from 'react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { baseGrid } from '../theme/mixins'

const NotFoundPage = () => {
  const NotFoundStyle = css`
    ${baseGrid}
    margin-bottom: var(--gutter-xlg);
    h1 {
      grid-column: 2 / -2;
      margin-top: 2em;
      margin-bottom: 0.25em;
    }
    p {
      grid-column: 2 / -2;
      max-width: 72ch;
    }
  `
  return (
    <Layout>
      <Seo title="404: Not found" />
      <section css={NotFoundStyle}>
        <h1>404: Not Found</h1>
        <p>
          You just hit a route that doesn&#39;t exist. 
        </p>
      </section>
    </Layout>
  )
}

export default NotFoundPage
